// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/index.tsx");
  import.meta.hot.lastModified = "1733471485307.8076";
}
// REMIX HMR END

import { useLoaderData } from '@remix-run/react';
import { getCollections } from '~/providers/collections/collections';
import { CollectionCard } from '~/components/collections/CollectionCard';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
export async function loader({
  request
}) {
  const collections = await getCollections(request, {
    take: 20
  });
  return {
    collections
  };
}
export default function Index() {
  _s();
  const {
    collections = []
  } = useLoaderData();
  const {
    t
  } = useTranslation();
  const headerImage = collections[0]?.featuredAsset?.preview;
  if (!collections.length) {
    return <div>No collections found</div>;
  }
  return <Suspense fallback={<div>Loading...</div>}>
      <>
        <section aria-labelledby="category-heading" className="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
          {/* title */}
          <div className="px-4 sm:px-6 lg:px-8 xl:px-0">
            <h2 id="category-heading" className="text-3xl font-bold text-center mb-8 text-gray-900">
              {t('common.shopByCategory')}
            </h2>
          </div>

          {/* collection */}
          <div className="mt-6 flow-root">
            <div className="-my-2">
              <div className="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
                <div className="grid justify-items-center grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
                  {collections.map(collection => <CollectionCard key={collection.id} collection={collection} />)}
                </div>
              </div>
            </div>
          </div>

          {/* footer */}
          {/* <div className="mt-6 px-4 sm:hidden">
            <a
              href="~/routes/__cart/index#"
              className="block text-sm font-semibold text-primary-600 hover:text-primary-500"
            >
              {t('common.browseCategories')}
              <span aria-hidden="true"> &rarr;</span>
            </a>
           </div> */}
        </section>
      </>
    </Suspense>;
}
_s(Index, "fenS8ORNfi1fUAhX/xakSGRh9qQ=", false, function () {
  return [useLoaderData, useTranslation];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;